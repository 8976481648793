html {
  scroll-behavior: smooth;
}

*,::after,::before {
  box-sizing: border-box;
}

body {
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'Open Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #060606;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1600px;
  padding: 80px 0;
  font-family: Poppins;
}

@media screen and (max-width: 899px){
  body {
    padding: 48px 16px;
  }
}
@font-face {
  font-family: "Jotia-Light";
  src: local("Jotia-Light"), url(./assets/fonts/Jotia-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(./assets/fonts/Poppins.ttf) format("truetype");
}

@font-face {
  font-family: "SchnyderM-Light-App";
  src: local("SchnyderM-Light-App"), url(./assets/fonts/SchnyderM-Light-App.ttf) format("truetype");
}
